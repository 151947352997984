exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-avmelding-js": () => import("./../../../src/pages/avmelding.js" /* webpackChunkName: "component---src-pages-avmelding-js" */),
  "component---src-pages-brukerbetingelser-js": () => import("./../../../src/pages/brukerbetingelser.js" /* webpackChunkName: "component---src-pages-brukerbetingelser-js" */),
  "component---src-pages-gratisting-med-hoy-verdi-js": () => import("./../../../src/pages/gratisting-med-hoy-verdi.js" /* webpackChunkName: "component---src-pages-gratisting-med-hoy-verdi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kategorier-js": () => import("./../../../src/pages/kategorier.js" /* webpackChunkName: "component---src-pages-kategorier-js" */),
  "component---src-pages-nye-gratisting-js": () => import("./../../../src/pages/nye-gratisting.js" /* webpackChunkName: "component---src-pages-nye-gratisting-js" */),
  "component---src-pages-om-gratis-no-js": () => import("./../../../src/pages/om-gratis.no.js" /* webpackChunkName: "component---src-pages-om-gratis-no-js" */),
  "component---src-pages-personvern-js": () => import("./../../../src/pages/personvern.js" /* webpackChunkName: "component---src-pages-personvern-js" */),
  "component---src-pages-populare-gratisting-js": () => import("./../../../src/pages/populare-gratisting.js" /* webpackChunkName: "component---src-pages-populare-gratisting-js" */),
  "component---src-templates-external-visit-js": () => import("./../../../src/templates/externalVisit.js" /* webpackChunkName: "component---src-templates-external-visit-js" */),
  "component---src-templates-offers-js": () => import("./../../../src/templates/offers.js" /* webpackChunkName: "component---src-templates-offers-js" */),
  "component---src-templates-tag-page-js": () => import("./../../../src/templates/tagPage.js" /* webpackChunkName: "component---src-templates-tag-page-js" */)
}

